import React, { Component } from 'react';
import './styles/core.scss'
import { MainPage } from './components/MainPage';



class App extends Component {
  render() {
    return (
      <div className="App">
        <MainPage/>
      </div>
    );
  }
}

export default App;
